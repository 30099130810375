import React from 'react'
import { Link } from 'gatsby'
import './style.css'

const TextLink = ({ to, href, children }) => {
    if (href) {
        return (
            <a className='text-link' href={href} target='_blank' rel="noreferrer">
                {children}
            </a>
        )
    } else {
        const active = typeof window !== `undefined` ? window.location.pathname.indexOf(to) > -1 : false;

        return (
            <Link className='text-link' style={{color: active ? '#FF2ACF' : '#FFFFFF'}} to={to}>
                {children}
            </Link>
        )
    }
}

export default TextLink

import React from 'react'
import { Link } from 'gatsby'
import TextLink from '../TextLink'
import logo from '../../images/savr-logo.png'
import './style.css'

const Nav = () => (
    <nav className='nav'>
        <div className="nav__logo">
            <Link to='/'><img src={logo} alt='savr logo' /></Link>
        </div>
        <div className='nav__links'>
            <TextLink to='/store'>**SAVR STORE**</TextLink>
            <TextLink to='/artist/blackostume'>BlacKostume</TextLink>
            <TextLink href='https://open.spotify.com/artist/4W3h8K4Nf1R8nRD2M9D60O'>The Phantom Friends</TextLink>
            <TextLink to='/artist/hny-wlsn'>HNY WLSN</TextLink>
            <TextLink to='/artist/darling-coils'>Darling Coils</TextLink>
            <TextLink to='/artist/counterfit'>Counterfit</TextLink>
        </div>
    </nav>
)

export default Nav

import React from 'react'
import TextLink from '../TextLink'
import './style.css'

const Footer = () => (
    <footer className='footer'>
        <div className='footer__links'>
            <TextLink href="mailto:savr.music@gmail.com">email</TextLink>
            <TextLink href="https://www.youtube.com/channel/UCHs7GcjTAoPY-u7Pk4ivNOA/videos">youtube</TextLink>
            <TextLink href="https://www.instagram.com/savr.music/">instagram</TextLink>
            <TextLink href="https://soundcloud.com/user-185791299/">soundcloud</TextLink>
            <TextLink href="https://twitter.com/savrmusic">twitter</TextLink>
            <TextLink href="https://www.facebook.com/SAVR-648189539130086">facebook</TextLink>
        </div>
    </footer>
)

export default Footer

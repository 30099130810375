import React from 'react'
import Nav from '../Nav'
import Footer from '../Footer'
import './style.css'

const Layout = ({ children }) => {
    return (
        <>
            <Nav />
            <main>{children}</main>
            <Footer />
        </>
    )
}

export default Layout
